import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/ecommerce/index.vue"),
  },
  // {
  //   path: "/dashboard/crypto",
  //   name: "dashboard-crypto",
  //   meta: {
  //     title: "Crypto",
  //     authRequired: true,
  //   },
  //   component: () => import("../views/dashboard/crypto/index"),
  // },
  // {
  //   path: "/dashboard/projects",
  //   name: "dashboard-projects",
  //   meta: {
  //     title: "Projects", authRequired: true,
  //   },
  //   component: () => import("../views/dashboard/projects/index"),
  // },
  // {
  //   path: "/dashboard/crm",
  //   name: "dashboard-crm",
  //   meta: {
  //     title: "CRM", authRequired: true,
  //   },
  //   component: () => import("../views/dashboard/crm/index"),
  // },
  // {
  //   path: "/dashboard/analytics",
  //   name: "dashboard-analytics",
  //   meta: {
  //     title: "Analytics", authRequired: true,
  //   },
  //   component: () => import("../views/dashboard/analytics/index"),
  // },
  // {
  //   path: "/dashboard/nft",
  //   name: "dashboard-nft",
  //   meta: {
  //     title: "NFT Dashboard", authRequired: true,
  //   },
  //   component: () => import("../views/dashboard/nft/index"),
  // },
  // {
  //   path: "/dashboard/job",
  //   name: "dashboard-job",
  //   meta: {
  //     title: "Job Dashboard", authRequired: true,
  //   },
  //   component: () => import("../views/dashboard/job/index"),
  // },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout/basic")
  },
  {
    path: "/chat",
    name: "chat",
    meta: { title: "Chat", authRequired: true },
    component: () => import("../views/apps/chat"),
  },
  {
    path: "/apps-file-manager",
    name: "file manager",
    meta: { title: "File Manager", authRequired: true },
    component: () => import("../views/apps/filemanager"),
  },
  {
    path: "/apps-todo",
    name: "To Do List",
    meta: { title: "To Do List", authRequired: true },
    component: () => import("../views/apps/todo"),
  },
  {
    path: "/mailbox",
    name: "mailbox",
    meta: { title: "Mailbox", authRequired: true },
    component: () => import("../views/apps/email/mailbox"),
  },
  {
    path: "/email/email-basic",
    name: "email-basic",
    meta: { title: "Basic Action", authRequired: true },
    component: () => import("../views/apps/email/email-basic"),
  },
  {
    path: "/email/email-ecommerce",
    name: "email-ecommerce",
    meta: { title: "Ecommerce Action", authRequired: true },
    component: () => import("../views/apps/email/email-ecommerce"),
  },
  {
    path: "/apps/nft-auction",
    name: "auction",
    meta: { title: "Live Auction", authRequired: true },
    component: () => import("../views/apps/nft/nft-auction"),
  },
  {
    path: "/apps/nft-collection",
    name: "collection",
    meta: { title: "Collactions", authRequired: true },
    component: () => import("../views/apps/nft/nft-collection"),
  },
  {
    path: "/apps/nft-create",
    name: "create",
    meta: { title: "Create", authRequired: true },
    component: () => import("../views/apps/nft/nft-create"),
  },
  {
    path: "/apps/nft-creators",
    name: "creators",
    meta: { title: "Creators", authRequired: true },
    component: () => import("../views/apps/nft/nft-creators"),
  },
  {
    path: "/apps/nft-explore",
    name: "explore",
    meta: { title: "Explore Now", authRequired: true },
    component: () => import("../views/apps/nft/nft-explore"),
  },
  {
    path: "/apps/nft-item-detail",
    name: "item-detail",
    meta: { title: "Item Detail", authRequired: true },
    component: () => import("../views/apps/nft/nft-item-detail"),
  },
  {
    path: "/apps/nft-marketplace",
    name: "marketplace",
    meta: { title: "Marketplace", authRequired: true },
    component: () => import("../views/apps/nft/nft-marketplace"),
  },
  {
    path: "/apps/nft-ranking",
    name: "ranking",
    meta: { title: "Ranking", authRequired: true },
    component: () => import("../views/apps/nft/nft-ranking"),
  },
  {
    path: "/apps/nft-wallet",
    name: "nft-wallet",
    meta: { title: "Wallet", authRequired: true },
    component: () => import("../views/apps/nft/nft-wallet"),
  },
  {
    path: "/apps/projects-list",
    name: "projects-list",
    meta: { title: "Projects List", authRequired: true },
    component: () => import("../views/apps/projects/list"),
  },
  {
    path: "/apps/projects-overview",
    name: "projects Overview",
    meta: { title: "Projects Overview", authRequired: true },
    component: () => import("../views/apps/projects/overview"),
  },
  {
    path: "/apps/projects-create",
    name: "projects-create",
    meta: { title: "Projects Create", authRequired: true },
    component: () => import("../views/apps/projects/create"),
  },
  {
    path: "/apps/tasks-details",
    name: "tasks-details",
    meta: { title: "Tasks Details", authRequired: true },
    component: () => import("../views/apps/tasks/details"),
  },
  {
    path: "/apps/tasks-kanban",
    name: "tasks-kanban",
    meta: { title: "Tasks Kanban", authRequired: true },
    component: () => import("../views/apps/tasks/kanban"),
  },
  {
    path: "/apps/tasks-list-view",
    name: "tasks-list-view",
    meta: { title: "Taks List", authRequired: true },
    component: () => import("../views/apps/tasks/list-view"),
  },
  {
    path: "/apps/crm-contacts",
    name: "crm-contacts",
    meta: { title: "CRM Contacts", authRequired: true },
    component: () => import("../views/apps/crm/contacts"),
  },
  {
    path: "/apps/crm-companies",
    name: "crm-companies",
    meta: { title: "CRM companies", authRequired: true },
    component: () => import("../views/apps/crm/companies"),
  },
  {
    path: "/apps/crm-deals",
    name: "crm-deals",
    meta: { title: "CRM Deals", authRequired: true },
    component: () => import("../views/apps/crm/deals"),
  },
  {
    path: "/apps/crm-leads",
    name: "crm-leads",
    meta: { title: "CRM Leads", authRequired: true },
    component: () => import("../views/apps/crm/leads"),
  },
  {
    path: "/ecommerce/customers",
    name: "customers",
    meta: { title: "Customers", authRequired: true },
    component: () => import("../views/apps/ecommerce/customers"),
  },
  {
    path: "/ecommerce/products",
    name: "products",
    meta: { title: "Products", authRequired: true },
    component: () => import("../views/apps/ecommerce/products"),
  },
  {
    path: "/ecommerce/product-details",
    name: "product-detail",
    meta: { title: "Product Details", authRequired: true },
    component: () => import("../views/apps/ecommerce/product-details"),
  },
  {
    path: "/ecommerce/orders",
    name: "orders",
    meta: { title: "Orders", authRequired: true },
    component: () => import("../views/apps/ecommerce/orders"),
  },
  {
    path: "/ecommerce/order-details",
    name: "order-details",
    meta: { title: "Order Details", authRequired: true },
    component: () => import("../views/apps/ecommerce/order-details"),
  },
  {
    path: "/ecommerce/add-product",
    name: "add-product",
    meta: { title: "Create Product", authRequired: true },
    component: () => import("../views/apps/ecommerce/add-product"),
  },
  {
    path: "/ecommerce/shopping-cart",
    name: "shopping cart",
    meta: { title: "Shopping Cart", authRequired: true },
    component: () => import("../views/apps/ecommerce/shopping-cart"),
  },
  {
    path: "/ecommerce/checkout",
    name: "checkout",
    meta: { title: "Check Out", authRequired: true },
    component: () => import("../views/apps/ecommerce/checkout"),
  },
  {
    path: "/ecommerce/sellers",
    name: "sellers",
    meta: { title: "Sellers", authRequired: true },
    component: () => import("../views/apps/ecommerce/sellers"),
  },
  {
    path: "/ecommerce/seller-details",
    name: "seller-details",
    meta: { title: "Seller Details", authRequired: true },
    component: () => import("../views/apps/ecommerce/seller-details"),
  },
  {
    path: "/crypto/buy-sell",
    name: "buy/sell",
    meta: { title: "Buy & Sell", authRequired: true },
    component: () => import("../views/apps/crypto/buy-sell"),
  },
  {
    path: "/crypto/kyc",
    name: "kyc",
    meta: { title: "KYC Application", authRequired: true },
    component: () => import("../views/apps/crypto/kyc"),
  },
  {
    path: "/crypto/ico",
    name: "ico",
    meta: { title: "ICO List", authRequired: true },
    component: () => import("../views/apps/crypto/ico"),
  },
  {
    path: "/crypto/orders",
    name: "crypto-orders",
    meta: { title: "Orders", authRequired: true },
    component: () => import("../views/apps/crypto/orders"),
  },
  {
    path: "/crypto/wallet",
    name: "wallet",
    meta: { title: "My Wallet", authRequired: true },
    component: () => import("../views/apps/crypto/wallet"),
  },
  {
    path: "/crypto/transactions",
    name: "transactions",
    meta: { title: "Transactions", authRequired: true },
    component: () => import("../views/apps/crypto/transactions"),
  },
  {
    path: "/invoices/detail/:id?",
    name: "invoice-detail",
    meta: { title: "Invoice Details", authRequired: true },
    component: () => import("../views/apps/invoices/details"),
  },
  {
    path: "/invoices/list",
    name: "invoice-list",
    meta: { title: "Invoice List", authRequired: true },
    component: () => import("../views/apps/invoices/list"),
  },
  {
    path: "/invoices/create",
    name: "invoice-create",
    meta: { title: "Create Invoice", authRequired: true },
    component: () => import("../views/apps/invoices/create"),
  },
  {
    path: "/jobs/application",
    name: "job-application",
    meta: { title: "Job Application", authRequired: true },
    component: () => import("../views/apps/jobs/application"),
  },
  {
    path: "/jobs/candidate-grid",
    name: "job-candidate-grid",
    meta: { title: "Candidate Grid", authRequired: true },
    component: () => import("../views/apps/jobs/candidate-grid"),
  },
  {
    path: "/jobs/candidate-lists",
    name: "job-candidate-lists",
    meta: { title: "Candidate Lists", authRequired: true },
    component: () => import("../views/apps/jobs/candidate-lists"),
  },
  {
    path: "/jobs/categories",
    name: "job-categories",
    meta: { title: "Job Categories", authRequired: true },
    component: () => import("../views/apps/jobs/categories"),
  },
  {
    path: "/jobs/companies-list",
    name: "job-companies-list",
    meta: { title: "Companies List", authRequired: true },
    component: () => import("../views/apps/jobs/companies-list"),
  },
  {
    path: "/jobs/details",
    name: "job-details",
    meta: { title: "Job Details", authRequired: true },
    component: () => import("../views/apps/jobs/details"),
  },
  {
    path: "/jobs/grid-lists",
    name: "job-grid-lists",
    meta: { title: "Grid Lists", authRequired: true },
    component: () => import("../views/apps/jobs/grid-lists"),
  },
  {
    path: "/jobs/lists",
    name: "job-lists",
    meta: { title: "Job Lists", authRequired: true },
    component: () => import("../views/apps/jobs/lists"),
  },
  {
    path: "/jobs/new",
    name: "job-new",
    meta: { title: "New Job", authRequired: true },
    component: () => import("../views/apps/jobs/new"),
  },
  {
    path: "/jobs/statistics",
    name: "job-statistics",
    meta: { title: "Job Statistics", authRequired: true },
    component: () => import("../views/apps/jobs/statistics"),
  },
  {
    path: "/apps-api-key",
    name: "API Key",
    meta: { title: "API Key", authRequired: true },
    component: () => import("../views/apps/apikey"),
  },
  // {
  //   path: "/pages/starter",
  //   name: "starter",
  //   meta: { title: "Starter", authRequired: true },
  //   component: () => import("../views/pages/starter"),
  // },
  // {
  //   path: "/pages/profile",
  //   name: "profile",
  //   meta: { title: "Profile", authRequired: true },
  //   component: () => import("../views/pages/profile/simple"),
  // },
  // {
  //   path: "/pages/profile-setting",
  //   name: "profile-setting",
  //   meta: { title: "Setting", authRequired: true },
  //   component: () => import("../views/pages/profile/setting"),
  // },
  // {
  //   path: "/pages/maintenance",
  //   name: "maintenance",
  //   meta: { title: "Maintanance", authRequired: true },
  //   component: () => import("../views/pages/maintenance"),
  // },
  // {
  //   path: "/pages/coming-soon",
  //   name: "coming-soon",
  //   meta: { title: "Comming Soon", authRequired: true },
  //   component: () => import("../views/pages/coming-soon"),
  // },
  // {
  //   path: "/pages/timeline",
  //   name: "timeline",
  //   meta: { title: "Timeline", authRequired: true },
  //   component: () => import("../views/pages/timeline"),
  // },
  // {
  //   path: "/pages/faqs",
  //   name: "FAQs",
  //   meta: { title: "FAQs", authRequired: true },
  //   component: () => import("../views/pages/faqs"),
  // },
  // {
  //   path: "/pages/pricing",
  //   name: "pricing",
  //   meta: { title: "Pricing", authRequired: true },
  //   component: () => import("../views/pages/pricing"),
  // },
  // {
  //   path: "/pages/team",
  //   name: "team",
  //   meta: { title: "Team", authRequired: true },
  //   component: () => import("../views/pages/team"),
  // },
  // {
  //   path: "/pages/search-results",
  //   name: "search-results",
  //   meta: { title: "Search Results", authRequired: true },
  //   component: () => import("../views/pages/search-results"),
  // },
  // {
  //   path: "/pages/sitemap",
  //   name: "sitemap",
  //   meta: { title: "Sitemap", authRequired: true },
  //   component: () => import("../views/pages/sitemap"),
  // },
  // {
  //   path: "/pages/privacy-policy",
  //   name: "privacy-policy",
  //   meta: { title: "Privacy Policy", authRequired: true },
  //   component: () => import("../views/pages/privacy-policy"),
  // },
  // {
  //   path: "/pages/term-conditions",
  //   name: "term-conditions",
  //   meta: { title: "Term Conditions", authRequired: true },
  //   component: () => import("../views/pages/term-conditions"),
  // },
  {
    path: '/auth/signin-basic',
    name: 'signin-basic',
    meta: {
      title: "Signin Basic", authRequired: true,
    },
    component: () => import('../views/auth/signin/basic')
  },
  {
    path: '/auth/signin-cover',
    name: 'signin-cover',
    meta: {
      title: "Signin Cover", authRequired: true,
    },
    component: () => import('../views/auth/signin/cover')
  },
  {
    path: '/auth/signup-basic',
    name: 'signup-basic',
    meta: {
      title: "Signup Basic", authRequired: true,
    },
    component: () => import('../views/auth/signup/basic')
  },
  {
    path: '/auth/signup-cover',
    name: 'signup-cover',
    meta: {
      title: "Signup Cover", authRequired: true,
    },
    component: () => import('../views/auth/signup/cover')
  },
  {
    path: '/auth/reset-pwd-basic',
    name: 'reset-pwd-basic',
    meta: {
      title: "Reset Password", authRequired: true,
    },
    component: () => import('../views/auth/reset/basic')
  },
  {
    path: '/auth/reset-pwd-cover',
    name: 'reset-pwd-cover',
    meta: {
      title: "Reset Password", authRequired: true,
    },
    component: () => import('../views/auth/reset/cover')
  },
  {
    path: '/auth/create-pwd-basic',
    name: 'create-pwd-basic',
    meta: {
      title: "create Password", authRequired: true,
    },
    component: () => import('../views/auth/create/basic')
  },
  {
    path: '/auth/create-pwd-cover',
    name: 'create-pwd-cover',
    meta: {
      title: "create Password", authRequired: true,
    },
    component: () => import('../views/auth/create/cover')
  },
  {
    path: '/auth/lockscreen-basic',
    name: 'lock-screen-basic',
    meta: {
      title: "Lock Screen", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/basic')
  },
  {
    path: '/auth/lockscreen-cover',
    name: 'lock-screen-cover',
    meta: {
      title: "Lock Screen", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/cover')
  },
  {
    path: '/auth/twostep-basic',
    name: 'twostep-basic',
    meta: {
      title: "Two Step Auth", authRequired: true,
    },
    component: () => import('../views/auth/twostep/basic')
  }, {
    path: '/auth/twostep-cover',
    name: 'twostep-cover',
    meta: {
      title: "Two Step Auth", authRequired: true,
    },
    component: () => import('../views/auth/twostep/cover')
  },
  {
    path: '/auth/404',
    name: '404',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/500',
    name: '500',
    meta: {
      title: "Error 500", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/auth/404-basic',
    name: '404-basic',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-basic')
  },
  {
    path: '/auth/404-cover',
    name: '404-cover',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-cover')
  },
  {
    path: '/auth/ofline',
    name: 'ofline',
    meta: {
      title: "Offline", authRequired: true,
    },
    component: () => import('../views/auth/errors/ofline')
  },
  {
    path: '/auth/logout-basic',
    name: 'logout-basic',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/basic')
  },
  {
    path: '/auth/logout-cover',
    name: 'logout-cover',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/cover')
  },
  {
    path: '/auth/success-msg-basic',
    name: 'success-msg-basic',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/basic')
  },
  {
    path: '/auth/success-msg-cover',
    name: 'success-msg-cover',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/cover')
  },
  //
  {
    path: '/pages/Home/Home',
    name: 'Home',
    meta: {title: "Home", authRequired: true,},
    component: () => import('../views/pages/Home/Home')
  },
  {
    path: '/pages/master/FloorMaster',
    name: 'Floor Master',
    meta: {title: "Floor Master", authRequired: true,},
    component: () => import('../views/pages/master/FloorMaster')
  },
  {
    path: '/pages/master/WingMaster',
    name: 'Wing Master',
    meta: {title: "Wing Master", authRequired: true,},
    component: () => import('../views/pages/master/WingMaster')
  },
  {
    path: '/pages/master/RoomMaster',
    name: 'Room Master',
    meta: {title: "Room Master", authRequired: true,},
    component: () => import('../views/pages/master/RoomMaster')
  },
  {
    path: '/pages/master/RoleMaster',
    name: 'Role Master',
    meta: {title: "Role Master", authRequired: true,},
    component: () => import('../views/pages/master/RoleMaster')
  },
  {
    path: '/pages/master/AdminMaster',
    name: 'Admin Master',
    meta: {title: "Admin Master", authRequired: true,},
    component: () => import('../views/pages/master/AdminMaster')
  },
  {
    path: '/pages/master/EmployeeMaster',
    name: 'Employee Master',
    meta: {title: "Employee Master", authRequired: true,},
    component: () => import('../views/pages/master/EmployeeMaster')
  },
  {
    path: '/pages/master/EBCost',
    name: 'EBCost Master',
    meta: {title: "EBCost Master", authRequired: true,},
    component: () => import('../views/pages/master/EBCost')
  },
  {
    path: '/pages/master/TermsandConditionMaster',
    name: 'Terms and Condition Master',
    meta: {title: "Terms and Condition Master", authRequired: true,},
    component: () => import('../views/pages/master/TermsandConditionMaster')
  },
  {
    path: '/pages/master/ParkingVehicleMaster',
    name: 'Parking Vehicle Master',
    meta: {title: "Parking Vehicle Master", authRequired: true,},
    component: () => import('../views/pages/master/ParkingVehicleMaster')
  },
  {
    path: '/pages/master/CustomerParkingFee',
    name: 'Customer Parking Fee Master',
    meta: {title: "Customer Parking Fee Master", authRequired: true,},
    component: () => import('../views/pages/master/CustomerParkingFee')
  },
  {
    path: '/pages/master/GuestParkingFee',
    name: 'Guest Parking Fee Master',
    meta: {title: "Guest Parking Fee Master", authRequired: true,},
    component: () => import('../views/pages/master/GuestParkingFee')
  },
  {
    path: '/pages/master/TaxMaster',
    name: 'Tax Master',
    meta: {title: "Tax Master", authRequired: true,},
    component: () => import('../views/pages/master/TaxMaster')
  },
  {
    path: '/pages/master/Charges',
    name: 'Charges Master',
    meta: {title: "Charges Master", authRequired: true,},
    component: () => import('../views/pages/master/Charges')
  },
  {
    path: '/pages/master/TaxDetail',
    name: 'Tax Detail',
    meta: {title: "Tax Master", authRequired: true,},
    component: () => import('../views/pages/master/TaxDetail')
  },
  {
    path: '/pages/CustomerOnBoardingProcess',
    name: 'Customer On Boarding Process',
    meta: {title: "Customer On Boarding Process", authRequired: true,},
    component: () => import('../views/pages/CustomerOnBoardingProcess')
  },
  {
    path: '/pages/CustomerOnBoardingProcessList',
    name: 'Customer On Boarding Process List',
    meta: {title: "Customer On Boarding Process List", authRequired: true,},
    component: () => import('../views/pages/CustomerOnBoardingProcessList')
  },
  {
    path: '/pages/CustomerExitBoardingProcess',
    name: 'Customer Exit Boarding Process',
    meta: {title: "Customer Exit Boarding Process", authRequired: true,},
    component: () => import('../views/pages/CustomerExitBoardingProcess')
  },
  {
    path: '/pages/CleaningSchedule',
    name: 'Cleaning Schedule',
    meta: {title: "Cleaning Schedule", authRequired: true,},
    component: () => import('../views/pages/CleaningSchedule')
  },
  {
    path: '/pages/ConstructionServiceActivity',
    name: 'Construction Service Activity',
    meta: {title: "Construction Service Activity", authRequired: true,},
    component: () => import('../views/pages/ConstructionServiceActivity')
  },
  {
    path: '/pages/MonthlyEBCalculation',
    name: 'Monthly EB Calculation',
    meta: {title: "Monthly EB Calculation", authRequired: true,},
    component: () => import('../views/pages/MonthlyEBCalculation')
  },
  {
    path: '/pages/MonthlyRentBill',
    name: 'Monthly Rent Bill',
    meta: {title: "Monthly Rent Bill", authRequired: true,},
    component: () => import('../views/pages/MonthlyRentBill')
  },
  {
    path: '/pages/GuestDetails',
    name: 'Guest Details',
    meta: {title: "Guest Details", authRequired: true,},
    component: () => import('../views/pages/GuestDetails')
  },
  {
    path: '/pages/RoomServiceRequest',
    name: 'Room Service Request',
    meta: {title: "Room Service Request", authRequired: true,},
    component: () => import('../views/pages/RoomServiceRequest')
  },
  {
    path: '/pages/RoomExchangeandHistory/RoomExchange',
    name: 'Room Exchange',
    meta: {title: "Room Exchange", authRequired: true,},
    component: () => import('../views/pages/RoomExchangeandHistory/RoomExchange')
  },
  {
    path: '/pages/RoomExchangeandHistory/RoomHistory',
    name: 'Room History',
    meta: {title: "Room History", authRequired: true,},
    component: () => import('../views/pages/RoomExchangeandHistory/RoomHistory')
  },
  {
    path: '/pages/ExpensesandProfit/Expenses',
    name: 'Expenses',
    meta: {title: "Expenses", authRequired: true,},
    component: () => import('../views/pages/ExpensesandProfit/Expenses')
  },
  {
    path: '/pages/ExpensesandProfit/Profit',
    name: 'Profit',
    meta: {title: "Profit", authRequired: true,},
    component: () => import('../views/pages/ExpensesandProfit/Profit')
  },
  {
    path: '/pages/EmployeeAttendance',
    name: 'Employee Attendance',
    meta: {title: "Employee Attendance", authRequired: true,},
    component: () => import('../views/pages/EmployeeAttendance')
  },
  {
    path: '/pages/EmployeeSalary',
    name: 'Employee Salary',
    meta: {title: "Employee Salary", authRequired: true,},
    component: () => import('../views/pages/EmployeeSalary')
  },
  {
    path: '/pages/PrivacyPolicy/privacy-policy',
    name: 'Privacy Policy',
    meta: {title: "Privacy Policy", authRequired: true,},
    component: () => import('../views/pages/PrivacyPolicy/privacy_policy')
  },
];